@import 'parameter';
@import 'mixin';
@import 'status-indicator';


#task_export {
    @include no-footer;

    .modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    .task-export-type-controller {
        display: flex;
        align-items: center;
        font-size: 12px;

        .react-toggle {
            margin-left: 5px;
            margin-right: 5px;
        }

        .react-toggle--checked .react-toggle-track {
            background-color: #4d4d4d;
        }
    }

    .text-small {
        font-size: 12px;
    }
    
    .save-button {
        border-radius: 2px;
        min-width: 80px;
        color: $white-color;
        border-color: $turquoise-color;
        background-color: $turquoise-color;
    }

    .back-button {
        border-radius: 2px;
        min-width: 80px;
        color: $red-color;
    }
}