@import 'parameter';
@import 'mixin';
@import 'button';

#task_assign_user {
  .save-button {
    @include save-button;
  }

  .back-button {
    @include back-button;
  }

  @include no-footer;

  .task-generate-invitation-link {
    margin-bottom: 20px;
    max-width: 500px;

    .input-group-extend {
      margin-left: 8px;
    }

    .generate-button {
      background-color: $turquoise-color;
      border-color: $turquoise-color;

      &:hover {
        background-color: $turquoise-color-hover;
        border-color: $turquoise-color-hover;
      }
    }
  }

  .table-responsive {
    min-width: 300px;
  }

  .search-box {
    display: inline-flex;
    margin-bottom: 10px;
    max-width: 100%;
    width: 500px;

    svg {
      width: 20px;
      height: inherit;
      margin-right: 10px;
      margin-left: 10px;
    }
    .form-control {
      max-width: initial;
      width: 100%;
    }
  }

  .task-assign-user-table {
    margin-bottom: 0px;
    border-bottom: 1px solid #e2e3e4;

    th {
      padding: 8px;
      color: #373a3c;
      font-weight: 700;
      border-bottom: 1px solid #e2e3e4;
    }

    tbody {
      max-height: 620px;
      min-height: unset;

      tr:nth-child(odd) {
        background-color: #f9f9f9;
      }

      td {
        border-top: 0px;
        border-bottom: 0px;
        padding-left: 5px;
        padding-right: 5px;
        text-align: center;

        button {
          margin-left: 2px;
          margin-right: 2px;
        }
      }

      .input-checkbox {
        margin: 0px;
      }
    }
  }

  .task-action .col-md-12 {
    padding: 0;
  }

  @include for-tablet-portrait-up {
  }

  @include for-tablet-landscape-up {
  }

  @include for-desktop-up {
  }

  @include for-big-desktop-up {
  }
}
