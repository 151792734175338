@import 'parameter';
@import 'mixin';
@import 'button';

#user_create {
    .back-button {
        @include back-button;
    }

    .save-button {
        @include save-button;
    }

    .generate-button {
        @include save-button;
    }

    .submit-button {
        @include submit-button;
    }

    .reject-button {
        @include reject-button;
    }

    @include no-footer;

    .user-create-container {
        margin: 0 auto;
    }

    .confirm-change-password-checkbox {
        small {
            margin-top: 4px;
            color: $b3 !important
        }

        input {
            margin-top: 0;
        }
    }

    label {
        font-size: 13px;
        font-weight: 500;
        color: $b2;
        margin-bottom: 8px;

        &.select {
            overflow: hidden;
            position: relative;
            margin-bottom: -4px;
            width: 100%;

            &:after {
                font-family: "Font Awesome 5 Pro";
                font-weight: 400;
                content: "\f107";
                padding: 10px;
                position: absolute;
                right: 0;
                top: 27px;
                z-index: 1;
                text-align: center;
                width: 28px;
                pointer-events: none;
                box-sizing: border-box;
                font-size: 12px;
                line-height: 1;
                color: $b3;
            }

            select {
                margin-top: 8px;
            }
        }
    }

    input {
        background-color: $ww;
        border-color: $wd;
        border-radius: 0px;
        cursor: pointer;

        &:focus {
            box-shadow: none
        }
    }

    select {
        border-color: $wd;
        border-radius: 0px;
        cursor: pointer;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        &:focus {
            box-shadow: none
        }
    }

    // .node rect {
    //     cursor: pointer;
    //     fill: $ww;
    //     fill-opacity: 0.5;
    //     stroke: #3182bd;
    //     stroke-width: 1.5px;
    // }

    // .node text {
    //     font: 10px sans-serif;
    //     pointer-events: none;
    // }

    // path.link {
    //     fill: none;
    //     stroke: #9ecae1;
    //     stroke-width: 1.5px;
    // }

    // #chart {
    //     width: 100vw;
    //     position: relative;
    //     left: 50%;
    //     right: 50%;
    //     margin-left: -50vw;
    //     margin-right: -50vw;
    // }


    @include for-tablet-portrait-up {

        .user-create-container {
            max-width: 718px;
        }
    }

    @include for-tablet-landscape-up {}

    @include for-desktop-up {}

    @include for-big-desktop-up {}
}