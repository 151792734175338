// Content
@import 'typography';
@import 'tables';
// Components
@import 'accordion';
@import 'alerts';
// CSS for badges is already in mytheme.css
@import 'breadcrumb';
@import 'buttons';
@import 'cards';
@import 'carousel';
@import 'collapse';
@import 'dropdowns';
@import 'listgroup';
@import 'navs';
@import 'popovers-tooltips';
@import 'selection';
@import 'textfields';
@import 'progressbar';
@import 'pagination';
@import 'form-1';
@import 'loading-spinner';
@import 'loading-bubble';
@import 'wr-subject';
// Utilities
//@import 'borders';
// CSS for borders is already in mytheme.css

// for dummy
h1 {
    display: block;
    font-size: 1.5rem;
    font-weight: 500;
}

p.title {
    color: #335377;
    font-weight: 300;
    font-size: 1rem;
}

.py-60 {
    padding: 60px 0;
}