.popover {
    background-color: #101820;
    border: 0;
    max-width: 200px;
    opacity: 0.9;
    text-align: center;

    .arrow {
        width: 0.8rem;
        height: 0.4rem
    }
}

.bs-popover-top,
.bs-popover-auto[x-placement^="top"] {
    margin-bottom: 0.4rem;

    >.arrow {
        bottom: calc((0.4rem + 1px) * -1);

        &::before {
            bottom: 0;
            border-width: 0.4rem 0.4rem 0;
            border-top-color: transparent;
        }

        &::after {
            bottom: 1px;
            border-width: 0.4rem 0.4rem 0;
            border-top-color: #101820;
        }
    }
}

.bs-popover-right,
.bs-popover-auto[x-placement^="right"] {
    margin-left: 0.4rem;

    >.arrow {
        left: calc((0.4rem + 1px) * -1);
        width: 0.4rem;
        height: 0.8rem;
        margin: 0.125rem 0;

        &::before {
            left: 0;
            border-width: 0.4rem 0.4rem 0.4rem 0;
            border-right-color: transparent;
        }

        &::after {
            left: 1px;
            border-width: 0.4rem 0.4rem 0.4rem 0;
            border-right-color: #101820;
        }
    }
}

.bs-popover-bottom,
.bs-popover-auto[x-placement^="bottom"] {
    margin-top: 0.4rem;

    >.arrow {
        top: calc((0.4rem + 1px) * -1);

        &::before {
            top: 0;
            border-width: 0 0.4rem 0.4rem 0.4rem;
            border-bottom-color: transparent;
        }

        &::after {
            top: 1px;
            border-width: 0 0.4rem 0.4rem 0.4rem;
            border-bottom-color: #101820;
        }
    }
}

.bs-popover-left,
.bs-popover-auto[x-placement^="left"] {
    margin-right: 0.4rem;

    >.arrow {
        right: calc((0.4rem + 1px) * -1);
        width: 0.4rem;
        height: 0.8rem;
        margin: 0.125rem 0;

        &::before {
            right: 0;
            border-width: 0.4rem 0 0.4rem 0.4rem;
            border-left-color: transparent;
        }

        &::after {
            right: 1px;
            border-width: 0.4rem 0 0.4rem 0.4rem;
            border-left-color: #101820;
        }
    }
}

.popover-body {
    color: #fff;
    padding: 0.25rem 0.5rem;
}