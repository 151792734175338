@mixin for-phone-only {
    @media (max-width: 576px) {
        @content;
    }
}

@mixin for-tablet-portrait-up {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin for-tablet-landscape-up {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin for-desktop-up {
    @media (min-width: 1200px) {
        @content;
    }
}

@mixin for-big-desktop-up {
    @media (min-width: 1800px) {
        @content;
    }
}

@mixin no-footer {
    .body {
        height: calc(100vh - 50px);
        grid-row: 2/4;

        >.col-md-12 {
            max-width: 100%
        }
    }

    .footer {
        display: none;
    }
}