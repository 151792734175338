@import 'parameter';

@mixin react-tags {
  line-height: 18px;
  border-color: #e4e4e4;
  padding: 0px;

  .react-tags__selected-tag {
    @include react-tags-selected-tag;
  }
}

@mixin react-tags-outline {
  font-size: $tag;
  border-radius: 2px;
  border-color: $blue-color;
  background-color: $white-color;
  color: $blue-color;
  padding: 3px 8px;
  margin: 0px 4px 4px 0px;

  .react-tagsinput-remove {
    display: none;
  }
}

@mixin react-tags-selected-tag {
  margin: 3px;
  background-color: $turquoise-color;
  color: $white-color;
  height: 24px;
  font-size: 12px;
  padding: 2px 8px;
  border-radius: 2px;

  &:after {
    content: '\2715';
    color: #fff;
    margin-left: 10px;
  }
}
