@import 'parameter';
@import 'mixin';
@import 'button';

#task_recent_activity {
  .back-button {
    @include back-button;
  }

  @include no-footer;

  .table-responsive {
    min-width: 690px;
  }

  .task-recent-activity-table {
    margin-bottom: 0px;
    border-bottom: 1px solid #e2e3e4;

    thead {
      th {
        padding: 8px;
        color: #373a3c;
        font-weight: 700;
        border-bottom: 1px solid #e2e3e4;
      }
    }

    tbody {
      max-height: 620px;

      tr:nth-child(odd) {
        background-color: #f9f9f9;
      }

      td {
        a {
          color: $tur;
        }
        border-top: 0px;
        border-bottom: 0px;
      }
    }

    button#readmore {
      color: $tur;
      padding: 0;
      border: none;
      background: none;
    }
  }

  .back-to-task-list {
    margin-bottom: 20px;

    .col-md-12 {
      padding: 0;
    }

    .back-button {
      padding: 4px 12px;

      svg {
        margin-right: 8px;
      }
    }
  }

  @include for-tablet-portrait-up {
  }

  @include for-tablet-landscape-up {
  }

  @include for-desktop-up {
  }

  @include for-big-desktop-up {
  }
}
