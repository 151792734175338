.list-group-item {
    padding: .75rem;
    font-size: .8125rem;
    color: #6f7479;

    &.active {
        pointer-events: none;

        h4 {
            color: #fff;
        }
    }

    &.justify-content-between span i.danger {
        color: #c7334c;
        font-size: 1rem;
    }
}

.list-group-item-action.active:focus,
.list-group-item-action.active:hover {
    color: #fff
}

.list-group-item-action:focus,
.list-group-item-action:hover {
    color: #4f5155
}

.tabVertical>div:first-child {
    margin-right: -1px;
}