@import 'parameter';
@import 'mixin';
@import 'status-indicator';

#task_list {
  @include no-footer;

  .panel {
    display: inline-flex;
    justify-content: space-between;
  }

  .create-new-task {
    height: 32px;
    border-radius: 2px;
    font-size: 13px;
    padding: 5px 12px;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 20px;
    background-color: $tur;
    border-color: $tur;
    color: $ww;

    svg {
      margin-right: 10px;
    }
  }

  .search-box {
    display: inline-flex;
    margin-bottom: 10px;
    max-width: 100%;
    max-height: 32px;
    width: 350px;

    .form-control {
      max-width: initial;
      width: 100%;
      font-size: 13px;
      margin-right: 5px;
    }
    .search-button {
      height: 100%;
      background-color: $tur;
      border: none;
    }
  }

  .task-name {
    display: flex;

    .status {
      margin-right: 6px;
    }
  }

  th {
    padding: 8px;
    color: $b2;
    font-weight: 700;
    border-bottom: 1px solid $we;
    font-size: 13px;
  }

  tbody {
    max-height: 620px;

    tr:nth-child(odd) {
      background-color: $wf;
    }
  }

  td {
    border-top: 0px;
    border-bottom: 0px;
    a {
      color: $tur;
    }
    .btn-group {
      margin-left: 2px;

      button {
        padding: 2px 6px;
      }
    }

    .dropdown-menu {
      min-width: 120px;

      button {
        margin: 0;
      }
    }

    .dropdown-item:hover {
      background-color: $tur;
      color: $ww;
    }
  }

  @include for-tablet-portrait-up {
  }

  @include for-tablet-landscape-up {
  }

  @include for-desktop-up {
  }

  @include for-big-desktop-up {
  }
}
