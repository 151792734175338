

// Overwrite Bootstrap's variables here
// You can find them in node_modules/bootstrap/scss/_variables.scss
// Copy the variables you need into this file, don't modify files under node_modules/

// Some example variables that you can uncomment:

// Enabling shadows and gradients
//$enable-shadows: true;
//$enable-gradients: true;

// Changing the body background and text
//$body-bg: #d3e9eb;
//$body-color: #151417;

// Changing the border radius of buttons
//$border-radius: 15px;

// Changing the theme colors
//$primary: #202f41;
//$secondary: #436296;
//$success: #2bc550;
//$info: #495dff;
//$warning: #ef8143;
//$danger: #ff293a;
//$light: #dfe6ee;
//$dark: #0f1319;


// Adding (!) an additional theme color (ex. classes btn-cool, bg-cool)
$theme-colors: (
 "white": #fff,
 "facebook":#1877f2,
 "twitter":#55acee,
 "instagram":#e4405f,
 "forum":#3C3963,
 "news":#690,
 "blog":#f37a01,
 "youtube":#cd201f
);

$gray-100: #eeeded;
$gray-200: #dedcdb;
$gray-300: #cecac9;
$gray-400: #beb9b7;
$gray-500: #aea8a5;
$gray-600: #8e8b8a;
$gray-700: #6e6e70;
$gray-800: #4f5155;
$gray-900: #2f343b;
$border:#6f7479;
$text:#3f464c;
$black: #101820;
$blue: #335377;
$indigo: #7f50b2;
$purple: #995289;
$pink: #f27f93;
$red: #c7334c;
$orange: #f38333;
$yellow: #f3cf33;
$green: #9cca33;
$teal: #3381af;
$link-hover: #00629B;
$cyan: #33a3aa;
$white:#ffffff;
$primary-hover:#002855;
$primary: $blue;
$secondary: $teal;
$spacer: 1rem;
$light: $gray-300;
$enable-hover-media-query: true;
$font-weight-normal: 400;
$link-color: $secondary;
$emphasized-link-hover-darken-percentage: 10%;
$link-hover-color: darken($link-color, 10%);
$grid-gutter-width: 1rem;
$border-color: $gray-100;
$border-radius: .125rem;
$border-radius-lg: .125rem;
$border-radius-sm: .1.25rem;
$body-bg:#ffffff;
$caret-width: .25em;
$font-family-sans-serif: 'Roboto', 'Sarabun', sans-serif;
$font-family-monospace: $font-family-sans-serif;
$font-weight-bold: 500;
$font-size-base: 1rem;
$h1-font-size: $font-size-base * 1.5;
$h2-font-size: $font-size-base * 1.25;
$h3-font-size: $font-size-base * 1.125;
$h4-font-size: $font-size-base;
$h5-font-size: $font-size-base * 0.875;
$h6-font-size: $font-size-base * 0.75;
$headings-font-family: $font-family-sans-serif;
$text-muted: $gray-500;
$blockquote-small-color: $gray-500;
$blockquote-font-size: $font-size-base;
$hr-border-color: $gray-100;
$table-cell-padding-sm: .25rem;
$table-hover-bg: rgba($black, .05);
$table-head-bg: $gray-100;
$table-head-color: $gray-800;
$table-dark-bg: $gray-900;
$input-btn-padding-y: .25rem;
$input-btn-font-family: $font-family-sans-serif;
$input-btn-padding-y-sm: 0;
$btn-disabled-opacity: .25;
$btn-block-spacing-y: .25rem;
$label-margin-bottom: .25rem;
$input-border-color: $gray-100;
$input-color: $gray-800;
$input-disabled-bg: $gray-100;
$input-placeholder-color: $gray-400;
$custom-control-indicator-size: .75rem;
$custom-select-indicator-padding: .5rem;
$nav-tabs-link-active-color: $gray-900;
$nav-pills-border-radius: 50rem;
$nav-link-padding-x: 1rem;
$nav-link-padding-y: 0;
$nav-divider-margin-y: 0;
$nav-link-disabled-color: $gray-200;
$nav-tabs-border-color: $gray-100;
$nav-tabs-link-hover-border-color: $nav-tabs-border-color;
$nav-tabs-link-active-border-color: $nav-tabs-border-color $nav-tabs-border-color $body-bg;
$nav-divider-color: $gray-100;
$navbar-padding-x: $spacer / 2;
$navbar-toggler-font-size: $font-size-base;
$navbar-dark-disabled-color: rgba($white, .1);
$dropdown-padding-y: 0;
$dropdown-border-color: $gray-100;
$dropdown-divider-bg: $gray-100;
$dropdown-item-padding-x: 1rem;
$card-spacer-y: .5rem;
$card-spacer-x: 1rem;
$card-border-color: $gray-100;
$card-cap-bg: null;
$card-img-overlay-padding:1rem;
$badge-font-size: 0.6875rem;
$badge-font-weight: $font-weight-normal;
$badge-padding-x: .75em;
$badge-border-radius: 50rem;
$badge-pill-padding-x: .5em;
$badge-pill-border-radius: 50rem;
$close-font-weight: $font-weight-normal;
$thumbnail-box-shadow: null;
$thumbnail-border-color: $gray-100;
$list-group-border-color: $gray-100;
$list-group-item-padding-y: .5rem;
$list-group-item-padding-x: 1rem;
$list-group-disabled-color: $gray-200;
$progress-height: .25rem;
$progress-box-shadow: 0;
$alert-padding-x: 1rem;
$alert-border-level: -2;
$alert-color-level: 8;
$modal-content-border-color: $gray-100;
$modal-content-border-radius: $border-radius;
$modal-content-box-shadow-xs: 0;
$modal-content-box-shadow-sm-up: 0;
$modal-footer-border-width: 0;
$modal-header-border-width: 0;
$dark: $gray-900;
$spacers: map-merge((0: 0, 1: ($spacer * .25), 2: ($spacer * .5), 3: ($spacer * .75), 4: $spacer, 5: ($spacer * 1.5)), ());
$box-shadow-sm: 0;
$box-shadow: 0;
$box-shadow-lg: 0;
$caret-vertical-align: $caret-width;
$caret-spacing: $caret-width * 1.5;
$font-size-sm: $font-size-base * .75;
$line-height-sm: 1.9;
$font-size-lg: $font-size-base;
$line-height-base: 1.6;
$headings-margin-bottom: $spacer;
$headings-line-height: 1;
$headings-color: $primary;
$mark-padding: .125em;
$mark-bg: $orange;
$hr-margin-y: $spacer / 2;
$spacer: 1rem;
$input-btn-font-size: $h5-font-size;
$input-btn-focus-box-shadow: 0;
$input-btn-line-height-lg: 1.4;
$btn-active-box-shadow: 0;
$btn-link-disabled-color: $gray-200;
$input-box-shadow: 0;
$yiq-contrasted-threshold: 200;
$navbar-dark-hover-color: rgba($white, .9);
$navbar-light-active-color: rgba($black, 1);
$navbar-light-hover-color: rgba($black, .8);
$close-text-shadow: 0;
$carousel-indicator-height: 8px;
$carousel-indicator-width: 8px;
$carousel-indicator-spacer: 4px;
$carousel-indicator-active-bg: $gray-900;
$carousel-control-color: $gray-400;
$carousel-control-width: 7%;
$carousel-control-opacity: .7;
$body-color: #3f464c;
$breadcrumb-divider: quote(">");
$dropdown-spacer: 0;
$dropdown-font-size: $font-size-base * .875;
$dropdown-box-shadow: 0;
$dropdown-item-padding-y: .5rem;
$dropdown-link-disabled-color: $gray-200;
$pagination-border-color: $gray-200;
$pagination-hover-bg: $link-hover-color;
$pagination-hover-color: $white;
$pagination-active-color: $white;
$pagination-active-bg: $link-hover-color;
$pagination-active-border-color: $link-hover-color;
$pagination-hover-border-color: $link-hover-color;
$pagination-disabled-color: $gray-200;
$alert-padding-y: 1rem;
$progress-border-radius: 0;
$progress-bar-color: $black;
$spinner-border-width-sm: .25em;
$breadcrumb-bg: transparent;
$breadcrumb-active-color: $body-color;
$breadcrumb-divider-color: $body-color;

// Breakpoints and media queries
$mobile: "(min-width: 320px) and (max-width: 812px)";