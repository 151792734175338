@import 'parameter';
@import 'mixin';

#create_user_management {
  @include for-phone-only {
    .container {
      padding-left: 0px;
      padding-right: 0px;
      margin: 0px;
    }

    .body {
      padding-left: 15px;
      padding-right: 15px;
      margin: auto;
      margin-top: calc(15vh - 60px);
      padding-top: 30px;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.3);
      transition: 0.3s;
      max-width: 360px;
      height: 400px;
      text-align: center;
      // position: absolute;
      // top: 15%;

      .create-user-interface {
        .create-user-group {
          margin: auto;
        }
      }

      .logo {
        // margin-bottom: -10px;

        img {
          height: 60px;
        }
      }

      .create-user-alert {
        height: 38px;
        padding: 0px;

        .error-alert {
          height: 38px;
          width: 300px;
          padding: 5px;
          margin: auto;
          border-radius: 0px;
        }
        .success-alert {
          height: 38px;
          width: 300px;
          padding: 5px;
          margin: auto;
          border-radius: 0px;
        }
        margin-bottom: 10px;
      }

      .form-control {
        max-width: 300px;
        margin-bottom: 10px;
        border-radius: 0px;
      }

      .create-user-button {
        max-width: 300px;
        background-color: $blue-color;
        margin-bottom: 10px;
        border-radius: 0px;

        &:disabled,
        &:hover {
          background-color: $blue-color-hover;
        }
      }

      .create-new-account-link-button {
        cursor: pointer;
        color: $blue-color;
        &:hover {
          color: $blue-color-hover;
        }
      }

      .footer {
        margin: 0px;
      }
    }
  }

  @include for-tablet-portrait-up {
    .container {
      padding-left: 0px;
      padding-right: 0px;
      margin: auto;
    }

    .body {
      padding-left: 15px;
      padding-right: 15px;
      margin: auto;
      margin-top: calc(15vh - 60px);
      padding-top: 30px;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.3);
      transition: 0.3s;
      max-width: 360px;
      height: 400px;
      text-align: center;
      // position: absolute;
      // top: 15%;

      .create-user-interface {
        .create-user-group {
          margin: auto;
        }
      }

      .logo {
        // margin-bottom: -10px;

        img {
          height: 60px;
        }
      }

      .create-user-alert {
        height: 38px;
        padding: 0px;

        .error-alert {
          height: 38px;
          width: 300px;
          padding: 5px;
          margin: auto;
          border-radius: 0px;
        }
        .success-alert {
          height: 38px;
          width: 300px;
          padding: 5px;
          margin: auto;
          border-radius: 0px;
        }
        margin-bottom: 10px;
      }

      .form-control {
        max-width: 300px;
        margin-bottom: 10px;
        border-radius: 0px;
      }

      .create-user-button {
        max-width: 300px;
        background-color: $blue-color;
        margin-bottom: 10px;
        border-radius: 0px;

        &:disabled,
        &:hover {
          background-color: $blue-color-hover;
        }
      }

      .create-new-account-link-button {
        cursor: pointer;
        color: $blue-color;
        &:hover {
          color: $blue-color-hover;
        }
      }

      .footer {
        margin: 0px;
      }
    }
  }

  @include for-tablet-landscape-up {
    .container {
      padding-left: 0px;
      padding-right: 0px;
    }

    .body {
      padding-left: 15px;
      padding-right: 15px;
      margin: auto;
      margin-top: calc(21vh - 60px);
      padding-top: 30px;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.3);
      transition: 0.3s;
      max-width: 360px;
      height: 400px;
      text-align: center;
      // position: absolute;
      // top: 15%;

      .create-user-interface {
        .create-user-group {
          margin: auto;
        }
      }

      .logo {
        // margin-bottom: -10px;

        img {
          height: 60px;
        }
      }

      .create-user-alert {
        height: 38px;
        padding: 0px;

        .error-alert {
          height: 38px;
          width: 300px;
          padding: 5px;
          margin: auto;
          border-radius: 0px;
        }
        .success-alert {
          height: 38px;
          width: 300px;
          padding: 5px;
          margin: auto;
          border-radius: 0px;
        }
        margin-bottom: 10px;
      }

      .form-control {
        max-width: 300px;
        margin-bottom: 10px;
        border-radius: 0px;
      }

      .create-user-button {
        max-width: 300px;
        background-color: $blue-color;
        margin-bottom: 10px;
        border-radius: 0px;

        &:disabled,
        &:hover {
          background-color: $blue-color-hover;
        }
      }

      .create-new-account-link-button {
        cursor: pointer;
        color: $blue-color;
        &:hover {
          color: $blue-color-hover;
        }
      }

      .footer {
        margin: 0px;
      }
    }
  }

  @include for-desktop-up {
    .container {
      padding-left: 0px;
      padding-right: 0px;
    }

    .body {
      padding-left: 15px;
      padding-right: 15px;
      // vertical-align: middle;
      margin: auto;
      margin-top: calc(43vh - 160px - 60px);
      vertical-align: middle;

      padding-top: 30px;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.3);
      transition: 0.3s;
      max-width: 360px;
      height: 400px;
      text-align: center;
      // position: absolute;
      // top: 15%;

      .create-user-interface {
        .create-user-group {
          margin: auto;
        }
      }

      .logo {
        // margin-bottom: -10px;

        img {
          height: 60px;
        }
      }

      .create-user-alert {
        height: 38px;
        padding: 0px;

        .error-alert {
          height: 38px;
          width: 300px;
          padding: 5px;
          margin: auto;
          border-radius: 0px;
        }
        .success-alert {
          height: 38px;
          width: 300px;
          padding: 5px;
          margin: auto;
          border-radius: 0px;
        }
        margin-bottom: 10px;
      }

      .form-control {
        max-width: 300px;
        margin-bottom: 10px;
        border-radius: 0px;
      }

      .create-user-button {
        max-width: 300px;
        background-color: $blue-color;
        margin-bottom: 10px;
        border-radius: 0px;

        &:disabled,
        &:hover {
          background-color: $blue-color-hover;
        }
      }

      .create-new-account-link-button {
        cursor: pointer;
        color: $blue-color;
        &:hover {
          color: $blue-color-hover;
        }
      }

      .footer {
        margin: 0px;
      }
    }
  }

  @include for-big-desktop-up {
    .container {
      padding-left: 0px;
      padding-right: 0px;
    }

    .body {
      padding-left: 15px;
      padding-right: 15px;

      margin: auto;
      margin-top: calc(43vh - 160px - 60px);
      padding-top: 30px;
      box-shadow: 0 2px 16px 0 rgba(0, 0, 0, 0.3);
      transition: 0.3s;
      max-width: 480px;
      height: 400px;
      text-align: center;
      // position: absolute;
      // top: 15%;

      .create-user-interface {
        .create-user-group {
          margin: auto;
        }
      }

      .logo {
        // margin-bottom: -10px;

        img {
          height: 60px;
        }
      }

      .create-user-alert {
        height: 38px;
        padding: 0px;

        .error-alert {
          height: 38px;
          width: 300px;
          padding: 5px;
          margin: auto;
          border-radius: 0px;
        }
        .success-alert {
          height: 38px;
          width: 300px;
          padding: 5px;
          margin: auto;
          border-radius: 0px;
        }
        margin-bottom: 10px;
      }

      .form-control {
        max-width: 360px;
        margin-bottom: 10px;
        border-radius: 0px;
        margin-left: auto;
        margin-right: auto;
      }

      .create-user-button {
        max-width: 360px;
        background-color: $blue-color;
        margin-bottom: 10px;
        border-radius: 0px;
        margin-left: auto;
        margin-right: auto;

        &:disabled,
        &:hover {
          background-color: $blue-color-hover;
        }
      }

      .create-new-account-link-button {
        cursor: pointer;
        color: $blue-color;
        &:hover {
          color: $blue-color-hover;
        }
      }

      .footer {
        margin: 0px;
      }
    }
  }
}
