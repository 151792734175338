@import 'custom-variables';

.wr-subject {
  margin: 50px auto;
  width: 50%;
  h1 {
    color: #3f464c;
    font-size: 18px;
    margin-bottom: 1rem;
  }
  .input-title {
    display: none;
  }

  // Mixin
  @mixin input-style {
    border: 1px solid #CFD0D2;
    border-radius: 2px;
    color: #3f464c;
    height: 56px;
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  // Extend
  %label-style {
    color: #3f464c;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: .5rem;
  }

  .section {
    margin-bottom: .825rem;
  }
  .keyword-input {
    position: relative;
    label {
      @extend %label-style;
    }
    input {
      @include input-style;
    }
  }

  .keyword-group {
    position: absolute;
    top: 3rem;
    left: 1rem;
    .keyword-tag {
      background-color: #3381AF;
      border-radius: 2px;
      color: #ffffff;
      font-weight: initial;
      display: inline-block;
      padding: 2px 8px;
      margin-right: 2px;
      margin-bottom: 0;
    }
    .tag-text {
      display: inline-block;
      font-size: 14px;
      margin: 0;
    }
    .close-keyword {
      font-size: 14px;
      display: inline-block;
      padding-left: .5rem;
      opacity: 1;
      i {
        color: #ffffff;
        font-weight: initial;
        vertical-align: -webkit-baseline-middle;
      }
      &:focus {
        outline: none;
      }
      &:hover {
        opacity: 1;
      }
    }
  }
  .tab {
    color: #6F7479;
    margin-top: .875rem;
    overflow: hidden;
    width: 100%;
    &-label {
      color: #3f464c;
      cursor: pointer;
      margin-bottom: 0;
      @extend %label-style;
      &:after {
        content:'\f078';
        color: #3f464c;
        font-family: "Font Awesome 5 Pro";
        font-size: 14px;
        font-weight: 500;
        padding-left: .5rem;
        vertical-align: bottom;
      }
    }
    .query {
      label {
        padding-top: .5rem;
      }
    }
    &-content {
      color: $body-color;
      max-height: 0;
    }
    input:checked {
      + .tab-label {
        &:after {
          content:'\f077';
          font-family: "Font Awesome 5 Pro";
        }
      }
      ~ .tab-content {
        max-height: 100vh;
      }
    }
  }
  .more-info {
    label {
      width: 100%;
      @extend %label-style;
    }
    .more {
      font-weight: 400;
      margin-bottom: 0;
    }
    input {
      @include input-style;
      padding: 1rem;
    }
  }

  .fields {
    margin-top: .825rem;
    .field-title, 
    .field-detail {
      margin: 0;
    }
    .field-title {
      @extend %label-style;
    }
    .field-detail {
      color: #aea8a5;
    }
  }
  
  .update-button {
    button {
      float: right;
    }
  }
  .clearfix::after {
    content: "";
    clear: both;
    display: table;
  }
}
