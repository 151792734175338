// @import 'custom-variables';

// .accordion {
//   display:flex;
//   margin: 50px auto;
//   width: 500px;

//   h1 {
//     font-size: 18px;
//     font-weight: bold;
//   }
//   input {
//     display: none;
//   }
//   .section {
//     flex: 1;
//   }
//   .tab {
//     color: white;
//     margin-bottom: .5rem;
//     overflow: hidden;
//     width: 100%;
//     &-label {
//       background: $primary;
//       border-radius: 2px;
//       cursor: pointer;
//       display: flex;
//       font-weight: medium;
//       justify-content: space-between;
//       padding: 1em;
//       margin-bottom: 0;
//       &:hover {
//         background-color: $primary;
//       }
//       &:after {
//         content:'\f107';
//         color: $white;
//         font-family: "Font Awesome 5 Pro";
//       }
//     }
//     &-content {
//       background-color: $gray-100;
//       color: $body-color;
//       max-height: 0;
//       padding: 0 1em;
//       transition: all .10s;
//     }
//     input:checked {
//       + .tab-label {
//         &:after {
//           content:'\f106';
//           color: $white;
//           font-family: "Font Awesome 5 Pro";
//         }
//       }
//       ~ .tab-content {
//         max-height: 100vh;
//         padding: 1em;
//       }
//     }
//   }
// }


@import 'custom-variables';

.accordion {
  display:flex;
  margin: 50px auto;
  width: 500px;

  h1 {
    font-size: 18px;
    font-weight: bold;
  }
  input {
    display: none;
  }
  .section {
    flex: 1;
  }
  .tab {
    color: #6F7479;
    margin-bottom: .5rem;
    overflow: hidden;
    width: 100%;
    &-label {
      border-bottom: 1px solid $gray-300;
      cursor: pointer;
      display: flex;
      font-size: 14px;
      font-weight: medium;
      justify-content: space-between;
      padding: 1em;
      margin-bottom: 0;
      &:after {
        content:'\f078';
        color: #6F7479;
        font-family: "Font Awesome 5 Pro";
        font-size: 14px;
        font-weight: lighter;
      }
    }
    &-content {
      color: $body-color;
      max-height: 0;
      padding: 0 1em;
      transition: all .10s;
    }
    input:checked {
      + .tab-label {
        color: $primary;
        border-color: $primary;
        &:after {
          content:'\f077';
          color: $primary;
          font-family: "Font Awesome 5 Pro";
        }
      }
      ~ .tab-content {
        max-height: 100vh;
        padding: 1em;
      }
    }
  }
}


