@import 'custom-variables';

$size: 30px;

@keyframes spinner {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner {
  position: absolute;
  top: calc(50% - #{$size / 2});
  left: calc(50% - #{$size / 2});
  width: $size;
  height: $size;
  animation: spinner 0.75s linear infinite;

  .spinner-inside {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
  
  .spinner-inner-1 {
    background: linear-gradient(to right, rgba($red,0) 0%,rgba($red,0) 50%,$red 51%);
  }
  
  .spinner-inner-2 {
    background: linear-gradient(to top, rgba($white,0) 0%,$white 100%);
  }
  
  .spinner-inner-3 {
    $offset: $size * 0.1; // 30 x .1 = 3
    background-color: $white;
    left: $offset; // 3
    top: $offset; // 3
    height: $size - ($offset * 2); // 30 - ( 3 x 2) = 24
    width: $size - ($offset * 2); // 30 - (3 x 2) = 24
  }
}

