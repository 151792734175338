$gray-100: #eeeded;
$gray-200: #dedcdb;
$gray-300: #cecac9;
$gray-400: #beb9b7;
$gray-500: #aea8a5;
$gray-600: #8e8b8a;
$gray-700: #6e6e70;
$gray-800: #4f5155;
$gray-900: #2f343b;
$primary: #335377;
$primary-hover:#002855;

.nav-link {
    height: 30px;
    line-height: 30px;
    font-size: .875rem;
    color: $gray-700;

    &:hover {
        background-color: $gray-100;
        color: $gray-700;
    }

    &.active {
        pointer-events: none;
        color: $gray-800;
    }
}

.nav-pills .nav-item {
    +.nav-item {
        margin-left: .5rem;
    }
}

.tab-pane {
	padding: 1rem;
}