@import 'parameter';
@import 'mixin';

#__react-alert__ {
  @include for-phone-only {
    .alert-template {
      div {
        div {
          font-size: 0.75em !important;
          font-weight: 300 !important;
          margin-top: 70px !important;
          min-width: 320px !important;
          border: 0px !important;
          border-radius: 0.125em !important;
          box-shadow: none !important;

          svg {
            margin-left: 5px;
            margin-right: 10px;
          }

          button {
            svg {
              stroke: #10282c;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  @include for-tablet-portrait-up {
    .alert-template {
      div {
        div {
          font-size: 0.75em !important;
          font-weight: 300 !important;
          margin-top: 60px !important;
          min-width: 320px !important;
          width: 600px !important;
          border: 0px !important;
          border-radius: 0.125em !important;
          box-shadow: none !important;

          svg {
            margin-left: 5px;
            margin-right: 10px;
          }

          button {
            svg {
              stroke: #10282c;
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }

  @include for-tablet-landscape-up {
    .alert-template {
      div {
        div {
          width: 600px !important;
        }
      }
    }
  }

  @include for-desktop-up {
    .alert-template {
      div {
        div {
          width: 900px !important;
        }
      }
    }
  }

  @include for-big-desktop-up {
  }
}
