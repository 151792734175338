@import 'custom-variables';

ul.pagination{
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 2rem 0;

  li {
    vertical-align: middle;
    .previous {
      &:after {
        content: '\f104';
        font-family: "Font Awesome 5 Pro";
      }
    }

    .more {
      &:after {
        content: '\f141';
        font-family: "Font Awesome 5 Pro";
      }
    }

    .next {
      &:after {
        content: '\f105';
        font-family: "Font Awesome 5 Pro";
      }
    }

    .active {
      background-color: $teal;
      border-color: $teal;
      color: $white;
      &:hover {
        color: $white;
      }
    }

    .disabled {
      border-color: $gray-200;
      color: $gray-200;
      cursor: auto;
      pointer-events: none;
    }

    a {
      border: 1px solid $gray-400;
      border-radius: 2px;
      color: $text;
      display: block;
      height: 28px;
      margin-right: .5rem;
      min-width: 28px;
      padding: 0 .5rem;
      line-height: 28px;
      text-align: center;
      transition: all .2s;
      text-decoration: none;
      
      &:hover {
        border-color: $teal;
        color: $teal;
      }
    }
    &:last-child {
      a {
        margin-right: 0;
      }
    }
  }
}