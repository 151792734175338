// $table-cell-padding:          .75rem !default;
// $table-cell-padding-sm:       .3rem !default;

// $table-color:                 $body-color !default;
// $table-bg:                    null !default;
// $table-accent-bg:             rgba($black, .05) !default;
// $table-hover-color:           $table-color !default;
// $table-hover-bg:              rgba($black, .075) !default;
// $table-active-bg:             $table-hover-bg !default;

// $table-border-width:          $border-width !default;
// $table-border-color:          $border-color !default;

// $table-head-bg:               $gray-200 !default;
// $table-head-color:            $gray-700 !default;

// $table-dark-color:            $white !default;
// $table-dark-bg:               $gray-800 !default;
// $table-dark-accent-bg:        rgba($white, .05) !default;
// $table-dark-hover-color:      $table-dark-color !default;
// $table-dark-hover-bg:         rgba($white, .075) !default;
// $table-dark-border-color:     lighten($table-dark-bg, 7.5%) !default;
// $table-dark-color:            $white !default;

// $table-striped-order:         odd !default;

// $table-caption-color:         $text-muted !default;

// $table-bg-level:              -9 !default;
// $table-border-level:          -6 !default;


// $table-cell-padding-sm: .25rem;
// $table-hover-bg: rgba($black, .05);
// $table-head-bg: $gray-100;
// $table-head-color: $gray-800;
// $table-dark-bg: $gray-900;

$gray-100: #eeeded;
$gray-200: #dedcdb;
$gray-300: #cecac9;
$gray-400: #beb9b7;
$gray-500: #aea8a5;
$gray-600: #8e8b8a;
$gray-700: #6e6e70;
$gray-800: #4f5155;
$gray-900: #2f343b;
$table:$gray-300;
$border:#6f7479;
$text:#3f464c;
$black: #101820;
$primary: #335377;

.search {
    position: relative;

    .tableSearch {
        min-width: 280px;
        padding-right: 30px;
        margin-bottom: 1rem;
        border: 0;
        border-bottom: 1px solid $table;
        color: $gray-800;
        font-size: .875rem;
        line-height: 2;

        &::placeholder,
        &:-ms-input-placeholder,
        &::-ms-input-placeholder {
            color: $gray-600;
            opacity: .8;
        }

        &:focus {
            border-color: $gray-800;
            outline: 0;
        }
    }

    button {
        padding: 0;
        margin: 0;
        border: 0;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 4px;
        right: 4px;
        background-color: transparent;
        color: $gray-600;

        &#clearIcon {
            display: none;
        }

        &#searchIcon {
            display: block;
        }

        i {}
    }
}

.table {
    border: 1px solid $table;
    position: relative;

    th,
    td {
        font-size: .875rem;
    }

    thead th {
        border-bottom: 1px solid $table;
        padding: 1rem;
        color: $border;
        font-size: .875rem;
        font-weight: 400;

        i {
            cursor: pointer;
            margin-left: .5rem;

            &.fa-sort {
                font-size: .75rem;
            }

            &.fal {
                color: $border;
            }

            &.fas {
                color: $border;
            }
        }
    }

    thead,
    tbody tr {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    tbody {
        z-index: 2;
        display: block;
        max-height: 300px;
        min-height: 60px;
        overflow-y: scroll;

        +tbody {
            border-top: 0;
        }


        &.noMatch {
            position: absolute;
            z-index: -1;
            top: 60px;

            td {
                text-align: center;
                color: $gray-800;
                background-color: #fff;
                cursor: default;
            }
        }

        tr {
            border-color: $gray-200;

            &:first-child td {
                border-top: 0;
            }
        }

        td {
            padding: 1rem;
            border-top-color: $table;
            color: $text;
            font-size: .875rem;

            p {
                margin-bottom: 0;
                color: $gray-600;
            }

            i {
                cursor: pointer;
                color: $gray-600;
                font-size: .875rem;

                &:hover {
                    color: $border;
                }
            }
        }
    }
}

.table-striped {
    tbody tr {
        &:nth-of-type(even) {
            background-color: #fff
        }

        &:nth-of-type(odd) {
            background-color: $gray-100
        }
    }
}

.table-hover {
    tr {
        display: flex;

        th,
        td {
            flex: 1;
            text-align: left
        }
    }

    tbody tr:hover {
        -webkit-box-shadow: 0px 3px 2px -2px rgba(170, 170, 170, 0.8);
        -moz-box-shadow: 0px 3px 2px -2px rgba(170, 170, 170, 0.8);
        box-shadow: 0px 3px 2px -2px rgba(170, 170, 170, 0.8);
        background-color: $gray-100;
        position: relative;
        z-index: 1;
        cursor: pointer;
    }
}

.tableCheckbox {

    thead th,
    tbody td {
        &.checkAll {
            width: 56px;
            max-width: 56px;
            padding-left: 1.5rem;
            text-align: center;
        }

        &.checkCol {
            width: 56px;
            max-width: 56px;
            padding-left: 1.5rem;
            text-align: center;
        }

        &.edit {
            width: 60px;
            max-width: 60px;
            text-align: center;
        }

        &.delete {
            width: 80px;
            max-width: 80px;
            text-align: center;
        }

        .custom-checkbox {
            .custom-control-label {
                &:before {
                    width: 1rem;
                    height: 1rem;
                    border-color: $table;
                    background-color: transparent;
                    top: .3rem;
                    cursor: pointer;
                    transition: none
                }

                &:after {
                    width: 1rem;
                    height: 1rem;
                    top: .3rem;
                    cursor: pointer;
                    background-color: transparent
                }

                &:hover::before {
                    border-color: $border
                }
            }

            .custom-control-input {
                &:checked {
                    ~.custom-control-label {
                        &:before {
                            border-color: $border;
                            background-color: $border
                        }

                        &:after {
                            background-image: none;
                            font-family: "Font Awesome 5 Pro";
                            content: "\f00c";
                            font-weight: 500;
                            font-size: inherit;
                            font-size: 10px;
                            text-align: center;
                            color: #fff;
                        }
                    }
                }

                &:focus:not(:checked)~.custom-control-label::before {
                    border-color: $table
                }
            }
        }
    }

    tbody tr.highlight {
        background-color: #ccdfeb !important;
    }
}

.tableCheckbox thead th .custom-checkbox .uncheck.custom-control-input:checked~.custom-control-label:after {
    content: "\f068";
}