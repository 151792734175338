@import 'custom-variables';

.alert {
  padding: 0.75rem 3.5rem 0.75rem 4.5rem;
  font-size: 0.75rem;
  font-weight: 300;
  border: 0;
  
  &.alert-success {
    color: #3E5913;
    background-color: #9CCA3340;

    .icon {
        border-color: #3E5913
    }
  }

  &.alert-danger {
      color: #750a20;
      background-color: #C7334C40;

      .icon {
          border-color: #750a20
      }
  }

  &.alert-warning {
      color: #6A5C13;
      background-color: #F3CF3340;

      .icon {
          border-color: #6A5C13
      }
  }

  &.alert-info {
      color: #0a3551;
      background-color: #3381af40;

      .icon {
          border-color: #0a3551
      }
  }

  &.alert-dismissible .close {
      font-size: 1rem;
      padding: 1.5rem 1rem;
  }

  .icon {
      position: absolute;
      top: .875rem;
      left: 1rem;
      width: 35px;
      height: 35px;
      border: 1px solid transparent;
      text-align: center;
      margin-right: 1.25rem;
      border-radius: 50rem;
      padding-top: .5625rem;

      i.fas {
          font-size: 0.875rem;
      }
  }

  .alert-heading {
      font-size: 0.875rem;
      font-weight: 500;
      margin-bottom: -0.0625rem;
  }
}
@media #{$mobile} {
  .alerts {
    .col {
      flex: none;
      width: 100%;
    }
  }
}
