@import 'custom-variables';

@keyframes bubble {
  0% {
    transform: scale(.65);
    opacity: 1;
  }
  100% {
    transform: scale(.45);
    opacity: .25;
  }

}
.bubble {
  display: flex;
  justify-content: center;
  margin-top: 10rem;
  .bubble-container {
    //width: 120px;
    width: 45px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .loading-bubble {
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background-color: $red;
  }
  
  .loading-bubble-1 {
      animation: bubble .4s ease 0s infinite alternate;
  }
  .loading-bubble-2 {
      animation: bubble .4s ease .2s infinite alternate;
  }
  .loading-bubble-3 {
      animation: bubble .4s ease .4s infinite alternate;
  }
}
