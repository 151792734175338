@import 'parameter';
@import 'mixin';
@import 'button';
@import 'tags';

#task_check_item {
  .save-button {
    @include save-button;
  }

  .skip-button {
    @include skip-button;
  }

  .reject-button {
    @include reject-button;
  }

  .back-button {
    @include back-button;
  }

  .next-button {
    @include next-button;
  }

  .parent-button {
    @include parent-button;
  }

  .embed-button {
    @include embed-button;
  }

  .add-button {
    @include add-button;
  }

  .duplicate-button {
    @include duplicate-button;
  }

  .delete-button {
    @include delete-button;
  }

  @include no-footer;

  .task-container .col-md-12 {
    padding: 0;
  }

  label {
    font-weight: 500;
  }

  .user-statistic {
    display: flex;
    position: absolute;
    top: -44px;
    right: 8px;

    .label {
      font-size: $p;
      font-weight: 700;
      margin-right: 8px;
    }

    .divider {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .media-heading {
    font-size: 20px;
    color: $dark-blue-color;
    margin-bottom: 10px;
  }

  .media-body {
    font-size: $detail;
    line-height: 28px;
  }

  .media-panel {
    margin-bottom: 0;
    button#readmore {
      color: $tur;
      padding: 0;
      border: none;
      background: none;
    }
  }

  .embed-view {
    margin-left: 0 !important;
    justify-content: center;
    padding-bottom: 20px;
  }

  .information-view,
  .parent-post-view {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 8px;

    font-size: 14px;
    color: $gray-color;
    text-align: left;
    margin-left: 0 !important;
    padding-top: 20px;

    svg {
      width: 14px;
      height: auto;
      margin-right: 4px;
    }

    div {
      padding-top: 1px;
      padding-bottom: 1px;
      padding-left: 4px;
      padding-right: 4px;
      border-radius: 4px;
      font-style: bold;
      font-weight: 500;
    }

    .facebook {
      background-color: #1972EB;
      color: white;
    }

    .x {
      background-color: #000000;
      color: white;
    }

    .instagram {
      background: rgb(134,8,244);
      background: linear-gradient(135deg, rgba(134,8,244,1) 0%, rgba(246,4,135,1) 70%);
      color: white;
    }
    .forum {
      background-color: #3c3963;
      color: white;
    }
    .news {
      background-color: #690;
      color: white;
    }
    .blog {
      background-color: #f37a01;
      color: white;
    }
    .youtube {
      background-color: #FF0000;
      color: white;
    }
    .tiktok {
      background-color: #00D6D3;
      color: white;
    }
  }

  .result-add {
    display: flex;
    margin: 0;
  }

  .react-tags {
    flex: 1;
    @include react-tags;

    &.is-focused {
      border-color: $tur;
    }
  }

  .react-tags__search-input {
    font-size: 14px;
    margin-top: 2px;
  }

  .add-button {
    flex: 1;
    transition: 0;
  }

  .result-view {
    margin: 0 0 8px;

    .react-tagsinput {
      padding: 0px;
      border: 0px;

      .react-tagsinput-tag {
        @include react-tags-outline;
      }
    }

    .react-tagsinput--focused {
      border-color: $gray-color;
    }

    .react-tagsinput-input {
      display: none;
    }

    button {
      font-size: 13px;
      max-width: 25px;
      height: 25px;
      padding: 0;
      margin-left: 8px;
      text-align: center;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .parent-view {
    .card {
      margin-bottom: 10px;

      .card-header {
        font-size: 14px;
        font-weight: bold;
      }

      .card-text {
        font-size: 14px;
      }
    }
  }

  .result-add {
    div {
      padding-right: 0px;
    }
  }

  .result-button-group {
    margin-top: 8px;
    margin-bottom: 8px;

    .result-button {
      min-width: 60px;
      margin: 4px 4px 4px 0px;
      padding: 3px 8px;
      background-color: $tur;
      border-color: $tur;

      &:focus {
        box-shadow: none;
      }

      &:hover {
        background-color: $turh;
        border-color: $turh;
      }
      &.disabled {
        pointer-events: none;
      }
    }
  }

  .task-note {
    margin-bottom: 20px;

    textarea {
      border-radius: 0px;
      padding-left: 8px;
    }
  }

  .check-item {
    padding: 15px;
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 20px;
    max-width: 100%;
    flex: 0 0 100%;

    &.even {
      background-color: #f5f5f5;
    }

    &.odd {
      background-color: #ffffff;
    }
  }

  .image-view {
    padding-top: 20px;

    img {
      max-height: 30rem;
      width: 100%;
      object-fit: contain;
      object-position: left;
    }
  }

  @include for-tablet-portrait-up {
  }

  @include for-tablet-landscape-up {
  }

  @include for-desktop-up {
  }

  @include for-big-desktop-up {
  }
}
