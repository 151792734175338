@import 'custom-variables';

.read-more {
  position: relative;
  margin: 2em auto;
  width: 80%;
  
  .read-button {
    color: $teal;
    text-decoration: none;
    &:hover {
      color: $link-hover;
    }
  }
  .show, .hide {
    position: absolute;
    bottom: -1rem;
    z-index: 100;
    @media screen and (max-width: 1024px) and (min-width: 320px) {
      bottom: -2rem;
    }
  }
  .hide {
    display: none;
  }
  .show:target {
    display: none;
  }
  .show:target ~ .hide {
    display: block;
  }
  .show:target ~ .content {
     max-height: 2000px;
  }
  .content {
    max-height: 95px;
    overflow: hidden;
    transition: max-height .2s ease-out;
  }
}



