$gray-100: #eeeded;
$gray-200: #dedcdb;
$gray-300: #cecac9;
$gray-400: #beb9b7;
$gray-500: #aea8a5;
$gray-600: #8e8b8a;
$gray-700: #6e6e70;
$gray-800: #4f5155;
$gray-900: #2f343b;
$border:#6f7479;
$text:#3f464c;
$primary-hover:#002855;

@mixin v-align() {
    top: 40%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
}

.dropdown {
    &.show .dropdown-toggle:after {
        transform: rotate(180deg);
    }

    .btn {
        &.btn-lg {
            &.dropdown-toggle:after {
                right: 1rem;
            }

            ~.dropdown-menu .dropdown-item {
                height: 40px;
                padding: .5rem 1rem;
                font-size: 1rem;
            }
        }

        &.btn-sm {
            &.dropdown-toggle:after {
                right: .5rem;
            }

            ~.dropdown-menu .dropdown-item {
                height: 24px;
                padding: .1875rem .5rem;
                font-size: .75rem;
            }
        }

        &.dropdown-toggle {
            border: 1px solid $border;
            color: $text;

            &:after {
                position: absolute;
                right: .75rem;
                border: 0;
                font-family: "Font Awesome 5 Pro";
                content: "\f107";
                font-weight: 400;
                font-size: inherit;
                transition: rotate .10s;
            }
        }

        &.btn-block {
            text-align: left;
        }
    }

    .dropdown-menu {
        width: 100%;
        border-color: $border;
        top: -1px !important;
        border-radius: 0 0 .125rem .125rem;

        .dropdown-item {
            height: 32px;
            color: $text;
            padding: .3125rem .75rem;

            &:hover {
                color: $white;
                background-color: $primary-hover
            }

            &.disabled {
                color: $gray-200;
            }
        }
    }
}


label.style {
    position: relative;
    margin: 0;
    font-weight: 400;
    font-size: .875rem;

    &.lg {
        font-size: 1rem;

        select {
            max-height: 40px;
        }

        &:after {
            top: 50%;
            right: 1rem;
        }
    }

    &.sm {
        font-size: .75rem;

        select {
            max-height: 24px;
        }

        &:after {
            top: 30%;
            right: .5rem;
        }
    }

    &:after {
        @include v-align();
        content: "\f107";
        font-family: "Font Awesome 5 Pro";
        font-size: inherit;
        color: $text;
        right: .75rem;
        position: absolute;
        pointer-events: none;
    }

    select {
        max-height: 32px;
        margin: 0;
        background-color: $white;
        color: $text;
        border-color: $border;
        outline: none;
        display: inline-block;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }
}


select[readonly] {
    pointer-events: none;
}