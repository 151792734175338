@import 'custom-variables';

$screen: 460px;

.steps-section {
  h2 {
    font-size: 16px;
    @media screen and (min-width: $screen) {
      text-align: center;
    }
    @media screen and (max-width: $screen) {
      margin: 1rem 3rem;
    }
  } 
  @media screen and (min-width: $screen) {
    padding: 0 1.5rem;
    max-width: 768px; 
    margin: 1.5rem auto;
  }
  @media screen and (max-width: $screen) {
	  width: 140px;
    float: left;
  }
}

.steps-timeline {
  border-color: $gray-200;
  position: relative;
  @media screen and (max-width: $screen) {
    border-left: 2px solid $gray-200;
    margin-left: .75rem;
  }
  @media screen and (min-width: $screen) {
    border-top: 2px solid $gray-200;
    padding-top: 1rem;
    margin: 5rem 1rem 0 1rem;
  }
}
.steps {
  position: relative;
  @media screen and (max-width: $screen) {
    padding-bottom: 1rem;
    margin-left: -25px;
    clear: both;
  }
  @media screen and (min-width: $screen) {
    float: left;  
    margin-top: -3.1rem;
    width: 20%;
  }
  &.first {
    left: -8%;
  }
  &.second {
    left: -4%;
  }
  &.third {
    left: 1%;
  }
  &.forth {
    left: 6%;
  }
  &.fifth {
    left: 9%;
  }
  
  .step-progressing {
    background-color: #335377;
    display: block;
    position: absolute;
    left: 57%;
    top: 33%;
    height: 2px;
    width: 165px;
  }
  
}

.circle { 
  background-color: $white;
  border: 2px solid $gray-200;
  border-radius: 50%;
  display: block;
  height: 25px;
  margin: 1.25rem auto .75rem;
  width: 25px;
  
  @media screen and (max-width: $screen) {
    float: left;
    margin-right: 10px;
    margin-left: 10px;
  }
  &.done {
    background-color: $primary;
    border-color: $primary;
    text-align: center;
    position: relative;
    &:before {
      content:'\f107';
      color: $white;
      font-family: "Font Awesome 5 Pro";
    }
  }

  &.current {
    background-color: $primary;
    border-color: $primary;
  }
}

.steps-name,
.steps-description {
  margin: 0;
}
.steps-name {
	@media screen and (max-width: $screen) {
	display: inline-block;
	margin-top: 10px;
}
  @media screen and (min-width: $screen) {
    text-align: center;
  }
}
.steps-description {
  color: $gray-700; 
  font-size: 13px;
  margin-top: .25rem;

  @media screen and (min-width: $screen) {
    text-align: center;
  }
}