// @import 'custom-variables';

// .textfield-error {
//   color: $red;
//   font-size: 12px;
//   margin-top: .5rem;
//   &:before {
//     content: '\f06a';
//     font-family: "Font Awesome 5 Pro";
//     margin-right: .25rem;
//   }
// }

// //Mixin
// @mixin input-field {
//   display: block;
//   position: relative;
// }

// //from placeholder to label
// @mixin animate-label {
//   label {
//     cursor: text;
//     color: $primary;
//     font-size: 14px;
//     left: 0;
//     position: absolute;
//     opacity: 1;
//     top: 0;
//     transition: all .2s;
//     @content;
//   }
// }
// @mixin placeholder {
//   //if we cursor placeholder will gone
//   &::placeholder,
//   &:placeholder-shown:not(:focus)::placeholder { 
//     opacity: 0;
//     transition: all .2s;
//   }
// }

// @mixin placeholder-textarea {
//   &::placeholder {
//     color: $gray-500;
//     opacity: 0;
//   }
//   &:placeholder-shown:not(:focus)::placeholder {
//     opacity: 1;
//     transition: all .2s;
//   }
// }

// @mixin placeholder-default {
//   /*placeholder default
//   all shown placeholder is same style but focus*/
//   &:placeholder-shown:not(:focus) + * {
//     color: $gray-500;
//     font-size: 16px;
//     @content;
//   }
// }
// .textfield {
//   margin: 0 auto;
//   padding: 3rem;
//   width: 50%;
//   @media #{$mobile} {
//     width: 100%;
//   }
// }
// .textfield-section {
//   @include input-field;
//   @include animate-label;
//   margin-bottom: 1rem;
  
//   .textfield-input {
//     @include placeholder;
//     padding-top: 1.4rem;
//     border-radius: 0;
//     border: none;
//     border-bottom: 1px solid $gray-500;
//     padding-left: 0;
//     width: 100%;

//     @include placeholder-default {
//       top: 1rem;
//     }
    
//     &:disabled {
//        border-color: $gray-200;
//       + label {
//         color: $gray-200 !important;
//       }
//     }

//     &:focus {
//       outline: none;
//       border-color: $primary;
//     }
    
//     &.error {
//       border-color: $red;
//       + label {
//         color: $red !important;
//       }
//     }
//   }
//   .textarea-input {
//     background-color: #f4f4f4;
//     border-radius: 0;
//     border: none;
//     @include placeholder-textarea;
//     padding: 1rem;
//     border: 0;
//     border-bottom: 1px solid $gray-500;
//     width: 100%;

//     &:focus {
//       outline: none;
//       border-color: $primary;
//     }
//   }
// }
@import 'custom-variables';

.textfield-error {
  color: $red;
  font-size: 12px;
  margin-top: .5rem;
  &:before {
    content: '\f06a';
    font-family: "Font Awesome 5 Pro";
    margin-right: .25rem;
  }
}

//Mixin
@mixin input-field {
  display: block;
  position: relative;
}

//from placeholder to label
@mixin animate-label {
  label {
    cursor: text;
    color: $primary;
    font-size: 14px;
    left: 0;
    position: absolute;
    opacity: 1;
    top: 0;
    transition: all .2s;
    @content;
  }
}
@mixin placeholder {
  //if we cursor placeholder will gone
  &::placeholder,
  &:placeholder-shown:not(:focus)::placeholder { 
    opacity: 0;
    transition: all .2s;
  }
}

@mixin placeholder-textarea {
  &::placeholder {
    color: #6F7479;
    opacity: 0;
  }
  &:placeholder-shown:not(:focus)::placeholder {
    opacity: 1;
    transition: all .2s;
  }
}

@mixin placeholder-default {
  /*placeholder default
  all shown placeholder is same style but focus*/
  &:placeholder-shown:not(:focus) + * {
    color: #6F7479;
    font-size: 16px;
    @content;
  }
}
.textfield {
  // margin: 0 auto;
  // padding: 3rem;
  // width: 50%;
  @media #{$mobile} {
    width: 100%;
  }
}
.textfield-section {
  @include input-field;
  @include animate-label;
  margin-bottom: 1rem;
  
  .textfield-input {
    @include placeholder;
    padding-top: 1.4rem;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #6F7479;
    padding-left: 0;
    width: 100%;

    @include placeholder-default {
      top: 1rem;
    }
    
    &:disabled {
       border-color: $gray-200;
      + label {
        color: $gray-200 !important;
      }
    }

    &:focus {
      outline: none;
      border-color: $primary;
    }
    
    &.error {
      border-color: $red;
      + label {
        color: $red !important;
      }
    }
  }
  .textarea-input {
    background-color: #f4f4f4;
    border-radius: 0;
    border: none;
    @include placeholder-textarea;
    padding: 1rem;
    border: 0;
    border-bottom: 1px solid #6F7479;
    width: 100%;

    &:focus {
      outline: none;
      border-color: $primary;
    }
  }
}

// @import 'custom-variables';

// .textfield-error {
//   color: $red;
//   font-size: 12px;
//   margin-top: .5rem;
//   &:before {
//     content: '\f06a';
//     font-family: "Font Awesome 5 Pro";
//     margin-right: .25rem;
//   }
// }

// //Mixin
// @mixin input-field {
//   display: block;
//   position: relative;
// }

// //from placeholder to label
// @mixin animate-label {
//   label {
//     background-color: $white;
//     cursor: text;
//     color: $primary;
//     font-size: 14px;
//     left: 0;
//     margin: 0 16px;
//     padding: 0 2px 0 2px;
//     position: absolute;
//     opacity: 1;
//     top: -10px;
//     transition: all .2s;
//     @content;
//   }
// }
// @mixin placeholder {
//   //if we cursor placeholder will gone
//   &::placeholder,
//   &:placeholder-shown:not(:focus)::placeholder { 
//     opacity: 0;
//     transition: all .2s;
//   }
// }

// @mixin placeholder-textarea {
//   &::placeholder {
//     color: $gray-500;
//     opacity: 0;
//   }
//   &:placeholder-shown:not(:focus)::placeholder {
//     opacity: 1;
//     transition: all .2s;
//   }
// }

// @mixin placeholder-default {
//   /*placeholder default
//   all shown placeholder is same style but focus*/
//   &:placeholder-shown:not(:focus) + * {
//     color: $gray-500;
//     font-size: 16px;
//     @content;
//   }
// }
// .textfield {
//   // margin: 0 auto;
//   // padding: 3rem;
//   // width: 50%;
//   @media #{$mobile} {
//     width: 100%;
//   }
// }
// .textfield-section {
//   @include input-field;
//   @include animate-label;
//   margin-bottom: 1rem;
  
//   .textfield-input {
//     @include placeholder;
//     border-radius: 4px;
//     border-bottom: 1px solid #6F7479;
//     width: 100%;
//     border: 1px solid $gray-500;
//     padding: 10px 0 8px 16px;

//     @include placeholder-default {
//       top: .6rem;
//     }
    
//     &:disabled {
//        border-color: $gray-200;
//       + label {
//         color: $gray-200 !important;
//       }
//     }

//     &:focus {
//       outline: none;
//       border-color: $primary;
//     }
    
//     &.error {
//       border-color: $red;
//       + label {
//         color: $red !important;
//       }
//     }
//   }
//   .textarea-input {
//     // background-color: #f4f4f4;
//     border-radius: 4px;
//     border: 1px solid #6F7479;
//     @include placeholder-textarea;
//     padding: 10px 0 8px 17px;
//     // border-bottom: 1px solid #6F7479;
//     width: 100%;

//     &:focus {
//       outline: none;
//       border-color: $primary;
//     }
//   }
// }
