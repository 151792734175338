@import 'custom-variables';

body.form {
  background-color: #f9f9f9;
}
.form-card {
  background-color: $white;
  border: 1px solid $gray-100;
  border-radius: 2px;
  padding: 2rem;
  margin: 2rem auto;
  width: 40%;

  p.sub-title {
    display: inline-block;
    padding-right: .25rem;
  }
  > span {
    vertical-align: text-top;
  }
  ul.tab-content {
    padding: 0;
    margin: 0;
    li {
      border-right: 1px solid #eeeded;
      display: inline-block;
      list-style: none;
      padding: 0 2rem 0 0rem;
      margin-right: 1.32rem;
      &:last-child {
        border-right: none;
      }
      a {
        text-align: center;
        text-decoration: none;
      }
    }
  }

  .textfield {
    margin-top: 1rem;
    .helper-text {
      font-size: 12px;
      margin-top: .5rem;
    }
  }

}