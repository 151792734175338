@import 'parameter';

@mixin icon-button {
  border-radius: 2px;
  width: 100%;
  max-width: 40px;
  margin-left: 10px;
}

@mixin action-button {
  border-radius: 2px;
  min-width: 80px;
  transition: 0;
  &.disabled {
    pointer-events: none;
  }
}

@mixin add-button {
  @include icon-button;

  max-width: 32px;
  margin-left: 8px;
  padding: 4px;
  text-align: center;
  border-radius: 2px;
  color: $white-color;
  border-color: $turquoise-color;
  background-color: $turquoise-color;

  &.disabled {
    color: $turquoise-color;
    border-color: $white-color;
    background-color: $white-color;
  }

  &:hover {
  }
}

@mixin duplicate-button {
  @include icon-button;

  border-radius: 2px;
  color: $black-color;
  border-color: $white-color;
  background-color: $white-color;

  &:disabled {
  }

  &:hover {
    border-color: $black-color;
  }
}

@mixin delete-button {
  @include icon-button;

  color: $red-color;
  border-color: $white-color;
  background-color: $white-color;

  &:disabled {
  }

  &:hover {
    border-color: $red-color;
  }
}

@mixin save-button {
  @include action-button;

  color: $white-color;
  border-color: $turquoise-color;
  background-color: $turquoise-color;

  &:disabled {
    border-color: $turquoise-color;
    background-color: $turquoise-color;
  }

  &:hover {
    border-color: $turquoise-color-hover;
    background-color: $turquoise-color-hover;
  }
}

@mixin submit-button {
  @include action-button;

  color: $white-color;
  border-color: $turquoise-color;
  background-color: $turquoise-color;

  &:disabled {
    border-color: $turquoise-color;
    background-color: $turquoise-color;
  }

  &:hover {
    border-color: $turquoise-color-hover;
    background-color: $turquoise-color-hover;
  }
}

@mixin download-button {
  @include action-button;

  svg {
    margin-right: 8px;
  }
  
  color: $white-color;
  border-color: $turquoise-color;
  background-color: $turquoise-color;

  &:disabled {
    border-color: $turquoise-color;
    background-color: $turquoise-color;
  }

  &:hover {
    border-color: $turquoise-color-hover;
    background-color: $turquoise-color-hover;
  }
}

@mixin reject-button {
  @include action-button;
  min-width: 80px;

  color: $red-color;
  border-color: $white-color;
  background-color: $white-color;

  &:disabled {
    color: $red-color-hover;
  }

  &:hover {
    color: $red-color-hover;
    border-color: $red-color-hover;
  }
}

@mixin skip-button {
  @include action-button;
  min-width: 80px;

  color: $warning-color;
  border-color: $white-color;
  background-color: $white-color;

  &:disabled {
    color: $warning-color-hover;
  }

  &:hover {
    color: $warning-color-hover;
    border-color: $warning-color-hover;
  }
}

@mixin back-button {
  @include action-button;

  color: #212224;
  border-color: $light-gray-color;
  background-color: $white-color;

  &:disabled {
    color: $light-gray-color-hover;
  }

  &:hover {
    background-color: #f0f1f1;
  }
}

@mixin next-button {
  @include action-button;

  color: #212224;
  border-color: $light-gray-color;
  background-color: $white-color;

  &:disabled {
    color: $light-gray-color-hover;
  }

  &:hover {
    background-color: #f0f1f1;
  }
}

@mixin btn-outline {
  background-color: #fff;
  border-color: #d3d5d6;
  color: #424548;
  height: 30px;
  padding: 3px 7px;
  border-radius: 2px;

  &.dropdown-toggle {
    background-color: #fff !important;
    border-color: #d3d5d6 !important;
    color: #424548 !important;
  }
}

@mixin parent-button {
  text-align: left;
  padding: 0px;
  margin: 8px 0;
  width: auto;
  svg {
    margin-right: 4px;
  }
}

@mixin embed-button {
  text-align: left;
  padding: 0px;
  margin: 8px 0;
  width: auto;
  svg {
    margin-right: 4px;
  }
}
