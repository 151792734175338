@import 'parameter';
@import 'mixin';
@import 'button';

#task_statistic {
  .back-button {
    @include back-button;
  }

  @include no-footer;

  .title {
    margin-bottom: 10 px;
  }

  .task-statistic-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .table-responsive {
    min-width: 690px;
  }

  .task-statistic-table {
    margin-bottom: 0px;
    border-bottom: 1px solid #e2e3e4;

    thead {
      th {
        padding: 8px;
        color: #373a3c;
        font-weight: 700;
        border-bottom: 1px solid #e2e3e4;
      }
    }

    tbody {
      max-height: 620px;

      tr:nth-child(odd) {
        background-color: #f9f9f9;
      }

      td {
        a {
          color: $tur;
        }
        border-top: 0px;
        border-bottom: 0px;
      }
    }
  }

  .panel {
    display: flex;
    justify-content: space-between;
  }

  .task-statistic {
    display: flex;
    align-items: center;

    .label {
      font-size: $p;
      font-weight: 700;
      margin-right: 8px;
    }

    .divider {
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .export-statistic-button {
    height: 32px;
    border-radius: 2px;
    font-size: 13px;
    padding: 5px 12px;
    margin-bottom: 20px;
    background-color: $tur;
    border-color: $tur;
    color: $ww;
  }

  .back-to-task-list {
    margin-top: 20px;
    margin-bottom: 20px;

    .col-md-12 {
      padding: 0;
    }

    .back-button {
      padding: 4px 12px;

      svg {
        margin-right: 8px;
      }
    }
  }

  @include for-tablet-portrait-up {
  }

  @include for-tablet-landscape-up {
  }

  @include for-desktop-up {
  }

  @include for-big-desktop-up {
  }
}
