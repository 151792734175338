@import 'parameter';
@import 'mixin';

.header {

    .navbar {
        height: 50px;
        margin: 0;
        background-color: #212224 !important;
        position: fixed;
        width: 100%;
        top: 0;
        z-index: 1020;

        .top-right {
            position: absolute;
            top: 0;
            right: 0;
            list-style: none;
            padding: 0;
            margin: 0;
            height: 49px;
            font-size: 0;

            li {
                a {
                    padding: 0 12px;
                    display: block;
                    height: 49px;
                    line-height: 49px;
                    color: #a8abae;

                    &:focus,
                    &:hover {
                        background-color: #212224;
                    }

                    svg {
                        margin: 0 0 0 4px;
                    }
                }
            }
        }

        .dropdown-menu {
            top: 50px !important;
            border-radius: 0 0 2px 2px;
            border: 1px solid #f0f1f1;
            box-shadow: 0 3px 6px -4px #b7b9bc;
            padding: 0px;

            button {
                display: block;
                padding: 6px 12px;
                color: #4d5154;
                font-size: 12px;
                line-height: 1.5;
                &:hover {
                    background-color: #f0f1f1;
                    color: #373a3c
                }
            }

            svg {
                margin: 0px 4px;
                width: 1em;
            }
        }
    }

    .sidebarToggler {
        position: absolute;
        padding: 0;
        font-size: 18px;
        margin: 0 0 0 -8px;
        color: #a8abae;
        height: 50px;
        width: 50px;
        line-height: 50px;
        text-align: center;
        outline: none;
        border: 0;
        background-color: transparent;

        .fa-bars {
            display: block;
            margin: 0 auto;
        }

        .fa-caret-right {
            display: none;
        }

        .fa-caret-left {
            display: none;
        }
    }

    .mobileLogo {
        display: inline-block;
        position: relative;
        left: 42px;
        top: -8px;
        width: 50px;
        height: 50px;

        .small {
            width: 36px;
            height: 36px;
            position: relative;
            top: 7px;
            left: 7px;
        }
    }

    @include for-tablet-portrait-up {
        background-color: #fff;

        .navbar {
            background-color: #fff !important;
            border-bottom: 1px solid #e2e3e4;
            width: calc(100% - 50px);

            .top-right li a {
                color: #2c2e2f;
                font-size: 12px;

                &:focus,
                &:hover {
                    background-color: #fff;
                }
            }
        }

        .sidebarToggler {
            padding: 0 4px;
            font-size: 13px;

            .fa-bars {
                display: none;
            }

            .fa-caret-right {
                display: block;
            }

            .fa-caret-left {
                display: block;
            }
        }

        .mobileLogo {
            display: none;
        }
    }

    @include for-tablet-landscape-up {}

    @include for-desktop-up {}

    @include for-big-desktop-up {}
}