@import 'parameter';
@import 'mixin';

#login {

    .layout {
        grid-template-rows: auto;
        grid-template-columns: auto;
    }

    .sidebar,
    .footer {
        display: none;
    }

    .container {
        width: 80%;
        background-color: #ffffff;
        border: 1px solid #eeeded;
        border-radius: 2px;
        box-shadow: 0 0 15px -8px #e2e3e4;
        padding: 40px 32px;
        margin: 160px auto;
        min-height: auto;
    }

    .body {
        grid-row: 1/4;
        grid-column: 1/3;

        .logo {
            height: 87px;
            text-align: center;
            margin-top: -14px;
            margin-bottom: 22px;

            img {
                width: 240px;
                height: auto;
            }
        }

        .login-alert {
            margin-bottom: 10px;

            .alert {
                text-align: center;
                padding: 7px;
                margin-bottom: 10px;
            }
        }

        .login-group {
            .form-group {
                margin-bottom: 3.8px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            label {
                color: #161718;
                font-size: 14px;
                margin-bottom: 0;
                font-weight: 400;
            }

            input {
                border: 1px solid #e2e3e4;
                border-radius: 2px;
                box-sizing: border-box;
                display: block;
                font-size: 12px;
                margin: 0.6em 0;
                padding: 12px 20px;
                width: 100%;
                transition: all 0.2s ease-in-out;
                color: #424548;
                height: 45px;
                box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
            }

            .text-danger {
                font-size: 12px;
                margin-bottom: 0;
                color: #c7324c;
                position: relative;
                margin-bottom: -20px;
            }

            button {
                background-color: #ba0020;
                border: 0;
                border-radius: 2px;
                color: #ffffff;
                font-size: 12px;
                font-weight: bold;
                margin-top: 38px;
                height: 42px;
                width: 100%;
                transition: all 0.2s ease-in-out;
            }
        }
    }

    @include for-tablet-portrait-up {
        .container {
            width: 400px;
        }
    }

    @include for-tablet-landscape-up {}

    @include for-desktop-up {}

    @include for-big-desktop-up {}
}