@import 'parameter';
@import 'mixin';
@import 'status-indicator';

#user_list {
  @include no-footer;

  .panel {
    display: inline-flex;
  }

  .create-new-user {
    height: 32px;
    border-radius: 2px;
    font-size: 13px;
    padding: 5px 12px;
    margin: 0;
    margin-right: 10px;
    margin-bottom: 20px;
    background-color: $tur;
    border-color: $tur;
    color: $ww;

    svg {
      margin-right: 10px;
    }
  }

  .table-responsive {
    min-width: 768px;
  }

  .search-box {
    display: inline-flex;
    margin-bottom: 10px;
    max-width: 100%;
    width: 350px;

    .form-control {
      max-width: initial;
      width: 100%;
      font-size: 13px;
    }
  }

  .user-list-table {
    margin-bottom: 0px;
    border-bottom: 1px solid $we;

    th {
      padding: 8px;
      color: $b2;
      font-weight: 700;
      border-bottom: 1px solid $we;
      font-size: 13px;
    }

    tbody {
      max-height: 620px;

      tr:nth-child(odd) {
        background-color: $wf;
      }
    }

    td {
      border-top: 0px;
      border-bottom: 0px;
    }
  }

  @include for-tablet-portrait-up {
  }

  @include for-tablet-landscape-up {
  }

  @include for-desktop-up {
  }

  @include for-big-desktop-up {
  }
}
