@import 'parameter';
@import 'mixin';

* {
  font-family: 'BlinkMacSystemFont', 'Segoe UI', 'Helvetica',
    'Arial', 'sans-serif', 'Apple Color Emoji', 'Segoe UI Emoji' !important;
}

html {
  overflow: hidden;
}

.layout {
  min-height: 100vh;
  margin: 0px;
  padding: 0px;
  max-width: 100% !important;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: 50px 1fr 50px;
  overflow: hidden;
  background-color: $b1;
}

.sidebar {
  grid-column: 1/2;
  grid-row: 1/4;
  width: 50px;
  background-color: $b1;

  &.expand {
    width: 250px;

    ~ .header,
    ~ .body,
    ~ .footer {
      opacity: 0.1;
      pointer-events: none;
    }

    ~ .header .navbar {
      width: calc(100% - 250px);
    }

    .logo {
      margin: 0;
      padding: 0;

      .large {
        display: block;
      }

      .small {
        display: none;
      }
    }

    .mobileMenuToggler {
      display: inline;
      position: absolute;
      top: 0;
      right: 2px;
      width: 50px;
      font-size: 18px;
      padding-top: 12px;
      text-align: center;
      color: #b7b9bc;

      .fa-angle-left {
        font-size: 12px;
        position: relative;
        top: -3px;
        right: 1px;
      }
    }

    .dropdown {
      .ch {
        padding: 7px 12px;
        color: $wd;
        font-size: 14px;
        line-height: 1.42857143;

        svg {
          width: 1.25em;
          margin-right: 12px;
        }

        .name {
          font-size: 13px;
        }
      }
    }

    .dropdownContent {
      overflow: hidden;
      display: none;
    }
  }

  &.collapsed {
    width: 0;
    overflow: hidden;

    .logo {
      .large {
        display: none;
      }

      .small {
        display: block;
        width: 36px;
        height: 36px;
        position: relative;
        top: 7px;
        left: 7px;
      }
    }

    header {
      height: 50px;
    }

    .dropdown {
      &:hover .dropdownContent {
        display: block;
      }

      .ch {
        text-align: center;
        height: 44px;
        line-height: 2.5;

        .name {
          display: none;
        }
      }
    }

    .dropdownContent {
      display: none;
      position: absolute;
      left: 49px;
      top: 0;
      background-color: $b1;
      min-width: 120px;
      z-index: 2;
      border-radius: 0 2px 2px 0;
      border-left: 1px solid $b0;
    }
  }

  header {
    position: relative;
    border-bottom: 1px solid $b1;
  }

  .logo img {
    width: 154px;
    height: 49px;
  }

  .mainMenu {
    border-bottom: 1px solid $b1;
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .dropdown {
    &.active {
      background-color: $b0;
      border-bottom: 1px solid $b1;

      .ch {
        color: $ww;
      }

      .dropdownContent {
        color: $ww;
        background-color: $b0;
      }
    }

    a:hover {
      text-decoration: none;

      .ch {
        color: $ww;
      }
    }

    .ch {
      color: $wd;
    }
  }

  .dropdownContent {
    left: 50px;
    top: 0;
    background-color: $b1;
    min-width: 100px;
    padding: 12px;
    color: $wd;
    font-size: 13px;
    text-align: left;
    height: 44px;
    line-height: 1.6;
  }

  @include for-tablet-portrait-up {
    &.expand {
      ~ .header,
      ~ .body,
      ~ .footer {
        opacity: 1;
        pointer-events: unset;
      }

      .logo {
        padding: 12px 12px 4px;
        height: 80px;
      }

      .mobileMenuToggler {
        display: none;
      }
    }

    &.collapsed {
      width: 50px;
      overflow: visible;

      .mobileMenuToggler {
        display: none;
      }
    }
  }
}

.header {
  grid-row: 1/2;
}

.body {
  grid-row: 2/3;
  overflow: hidden;
  line-height: 1.42857143;
  background-color: $ww;
  overflow-y: auto;
  padding: 0;
  margin: 0;

  > .col-md-12 {
    padding: 14px 14px 36px 14px;
    margin: 0 auto;
    max-width: 768px;

    @include for-tablet-landscape-up {
      max-width: 992px;
    }

    @include for-desktop-up {
      max-width: 100%;
    }
  }
}

.row {
  margin: 0;
}

.title {
  font-size: $title;
  font-weight: 400;
  margin-top: 4px;
  margin-bottom: 20px;
  color: $b1;
}

.table {
  overflow-x: auto;
  border: 0px;
  padding: 0;
  margin: 0;
}

.table-responsive {
  min-width: 1100px;
}

.task-list-table {
  border-bottom: 1px solid $we !important;
}

.action-button-group {
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  display: flex;
  text-align: right;

  .btn-group-1 {
    margin-left: 0px;
    margin-right: auto;
  }

  .btn-group-2 {
    margin-left: auto;
    margin-right: 0px;
  }

  .back-button {
    padding: 4px 12px;

    svg {
      margin-right: 8px;
    }
  }

  .next-button {
    padding: 4px 12px;

    svg {
      margin-left: 8px;
    }
  }
}

.pagination {
  display: flex;
  padding: 0;
  margin-top: 12px;

  .table-pagination {
    margin: auto;
  }

  .pagination {
    padding: 0;
  }

  .page-item button {
    font-size: 14px;
    background-color: $ww;
    border: 0;
    color: $b4;
    padding: 4px 8px;
    margin: 0 2px;
  }

  .page-item.active {
    button {
      color: $tur;
      background-color: $ww;
      font-weight: 700;
    }
  }
}

.footer {
  grid-row: 3/4;
  background-color: $ww;
}

::-webkit-scrollbar {
  width: 0px !important;
}

.btn,
.btn-link,
.page-link {
  transition: none !important;
  text-decoration: none !important;

  &:hover {
    text-decoration: none !important;
  }

  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
    text-decoration: none !important;
  }
}

input,
textarea {
  &:focus {
    outline: 0 !important;
    box-shadow: none !important;
    border-color: $tur !important;
  }
}

.start-button {
  margin-left: 2px;
  margin-right: 2px;
  font-size: 14px;
  min-width: 30px;
  height: 30px;
  text-align: center;
  line-height: 20px;
  padding: 2px;

  &:focus {
    background-color: $tur !important;
    border-color: $tur !important;
    color: $ww;
  }

  + .start-button {
    margin-left: 2px;
  }
}

.btn-outline {
  background-color: $ww;
  border-color: $wd;
  color: $b3;
  height: 30px;
  padding: 3px 7px;
  border-radius: 2px;

  &:hover {
    background-color: $tur;
    border-color: $tur;
    color: $ww;
  }

  &.dropdown-toggle {
    background-color: $ww !important;
    border-color: $wd !important;
    color: #424548 !important;
  }

  &:disabled,
  &.disabled {
    background-color: $we;
    border-color: $we;
    cursor: default;
  }
}

.read-more-button {
  margin-top: 4px;
}

.read-more-button,
.btn-link {
  color: $tur;
  cursor: pointer;

  &:hover {
    color: $turh;
  }
}

.none {
  display: none;
}

.spin {
  -webkit-animation: spin 2s infinite linear;
  animation: spin 2s infinite linear;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(359deg);
  }
}
