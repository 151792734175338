.carousel {

  &.mobile {
      padding: 0;

      .carousel-control-next,
      .carousel-control-prev {
          width: 12%;
      }

      .carousel-caption {
          right: 15%;
          left: 15%;
          h5 {
              margin-bottom: 20%;
          }
          p {
              display: inline-block;
              height: 60px;
              margin-bottom: 2%;
              line-height: 1.4;
          }
      }
  }

  .carousel-indicators {
      margin-bottom: 1.125rem;

      li {
          border-radius: 50rem;
          border: 0;
          opacity: 1;
          background-color: #9fa2a5;
      }

      .active {
          opacity: 1;
          background-color: #4f5155;
      }
  }

  .carousel-control-next:hover i,
  .carousel-control-prev:hover i {
      color: #2f343b;
      opacity: 1
  }

  .carousel-control-next-icon,
  .carousel-control-prev-icon {
      background-image: none;
      width: 40px;
      height: 40px;

      i {
          color: #6e6e70;
          opacity: 1;
      }
  }

  .carousel-caption {
      right: 35%;
      left: 35%;

      h5 {
          color: #6f7479;
          font-weight: 400;
          font-size: 1.125rem;
          margin-bottom: 15%
      }

      p {
          color: #9fa2a5;
          font-size: .875rem;
          margin-bottom: 5%;
          line-height: 1.2;
          height: 34px;
          overflow: hidden;
      }
  }
}