@import 'parameter';
@import 'mixin';
@import 'button';

#task_add_checkitem {
  .save-button {
    @include save-button;
  }

  .back-button {
    @include back-button;
  }

  .download-button {
    @include download-button;
    margin-bottom: 20px;
  }

  .link-button {
    color: $tur;
    padding: 0;
    border: none;
    background: none;
  }

  .custom-file-label {
    overflow: hidden;
  }

  @include no-footer;

  .task-add-checkitem-container {
    margin: auto;
    padding: 0;
    max-width: 360px;

    .task-add-checkitem-form {
      min-height: 180px;
      .start-button {
        min-width: 100%;
        min-height: 100%;
      }
      .start-button.disabled {
        background-color: #b4b4b4;
        color: black;
      }
    }
    .text-import-options {
      .form-group {
        margin-bottom: 13px;
      }
    }
  }

  .title {
    display: flex;
    justify-content: space-between;
  }

  .task-add-checkitem-mode-controller {
    display: flex;
    align-items: center;
    font-size: 12px;

    .react-toggle {
      margin-left: 5px;
      margin-right: 5px;
    }
    .start-button {
      min-width: 100%;
      min-height: 100%;
    }
  }

  .task-action {
    .col-md-12 {
      padding: 0;
    }
  }

  @include for-tablet-portrait-up {
  }

  @include for-tablet-landscape-up {
  }

  @include for-desktop-up {
  }

  @include for-big-desktop-up {
  }
}
