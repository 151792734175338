@import 'custom-variables';
$primaryh:#002855;
$secondaryh:#00629b;
$successh:#84bd00;
$infoh:#008c95;
$warningh:#f1c400;
$dangerh:#ba0020;
$lighth:#beb9b7;
$darkh:#101820;

.btn {

    &.iconOnly i {
        margin: 0;
    }

    i {
        margin-right: .75rem;
    }

    +.btn {
        margin-left: .75rem;
    }
}

.btn.btn-lg {

    i {
        margin-right: 1rem;
    }

    +.btn.btn-lg {
        margin-left: 1rem;
    }
}

.btn.btn-sm {

    i {
        margin-right: .5rem;
    }

    +.btn.btn-sm {
        margin-left: .5rem;
    }
}

.btn-primary:hover {
    color: #ffffff;
    background-color: $primaryh;
    border-color: $primaryh;
}

.btn-secondary:hover {
    color: #ffffff;
    background-color: $secondaryh;
    border-color: $secondaryh;
}

.btn-success:hover {
    color: #ffffff;
    background-color: $successh;
    border-color: $successh;
}

.btn-info:hover {
    color: #ffffff;
    background-color: $infoh;
    border-color: $infoh;
}

.btn-warning:hover {
    color: #ffffff;
    background-color: $warningh;
    border-color: $warningh;
}

.btn-danger:hover {
    color: #ffffff;
    background-color: $dangerh;
    border-color: $dangerh;
}

.btn-light:hover {
    color: #ffffff;
    background-color: $lighth;
    border-color: $lighth;
}

.btn-dark:hover {
    color: #ffffff;
    background-color: $darkh;
    border-color: $darkh;
}

.btn-white {
    color: #4f5155;
    background-color: #eeeded;
    border: 1px solid #eeeded;

    &:hover {
        color: #2f343b;
        background-color: #dedcdb;
        border-color: #dedcdb
    }
}

.btn-outline-primary:hover {
    color: #ffffff;
    background-color: $primaryh;
    border-color: $primaryh;
}

.btn-outline-secondary:hover {
    color: #ffffff;
    background-color: $secondaryh;
    border-color: $secondaryh;
}

.btn-outline-success:hover {
    color: #ffffff;
    background-color: $successh;
    border-color: $successh;
}

.btn-outline-info:hover {
    color: #ffffff;
    background-color: $infoh;
    border-color: $infoh;
}

.btn-outline-warning:hover {
    color: #ffffff;
    background-color: $warningh;
    border-color: $warningh;
}

.btn-outline-danger:hover {
    color: #ffffff;
    background-color: $dangerh;
    border-color: $dangerh;
}

.btn-outline-light:hover {
    color: #2f343b;
    background-color: $lighth;
    border-color: $lighth;
}

.btn-outline-dark:hover {
    color: #ffffff;
    background-color: $darkh;
    border-color: $darkh;
}

.btn-outline-white {
    color: #6e6e70;
    border-color: #6e6e70;

    &:hover {
        color: #4f5155;
        border-color: #4f5155;
        background-color: #eeeded
    }
}

.btn-group>.btn:not(:first-child) {
    margin-left: 0;
}

.btn-secondary-light {
  border: 1px solid $blue;
  color: $blue;
  &:hover {
    background: rgba(0, 40, 85, .2);
    color: $blue;
  }
}

// .sub-title {
//   font-size: 12px;
//   color: $gray-600;
// }