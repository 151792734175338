.card {
    .card-body {
        // font-size: 0;

        .card-title {
            font-size: 1.125rem;
            margin-bottom: .75rem;
        }

        .card-subtitle {
            font-weight: 400;
        }

        .card-text {
            font-size: .875rem;
            margin-bottom: .25rem;

            small {
                font-size: .75rem;
            }
        }

        .blockquote-footer {
            font-size: .75rem;
        }

        .btn-wrap {
            font-size: 0;
        }

        .btn {
            margin-top: .5rem;

            +.btn {
                margin-left: .5rem;
            }

            &.btn-block {
                margin-left: 0;
            }
        }

        .card-link {
            font-size: .875rem;
        }

        +.card-img-top {
            border-radius: 0;
        }
    }

    .card-img-overlay {
    	font-size: .875rem;
        .card-title {
            font-size: 1.125rem;
            color:#fff;
        }
    }

    &.h {
    	.card-title {
    		margin-bottom: .75rem;
    	}
    	.card-text {
    		line-height: 1.4
    	}
    }
}