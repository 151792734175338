@import 'parameter';
@import 'mixin';
@import 'button';


// <div class="redsmithdatafilter-channel-checkbox form-check"><span><label class=""><input name="facebook" type="checkbox" class="form-check-input" checked=""> Facebook</label></span><span><label class=""><input name="twitter" type="checkbox" class="form-check-input" checked=""> X</label></span><span><label class=""><input name="instagram" type="checkbox" class="form-check-input" checked=""> Instagram</label></span><span><label class=""><input name="youtube" type="checkbox" class="form-check-input" checked=""> YouTube</label></span><span><label class=""><input name="tiktok" type="checkbox" class="form-check-input" checked=""> TikTok</label></span><span><label class=""><input name="forum" type="checkbox" class="form-check-input" checked=""> Forum</label></span><span><label class=""><input name="news" type="checkbox" class="form-check-input" checked=""> News</label></span><span><label class=""><input name="blog" type="checkbox" class="form-check-input" checked=""> Blog</label></span></div>

#task_create {
  @include no-footer;

  .task-create-container {
    margin: 0 auto;
  }

  .node rect {
    cursor: pointer;
    fill: $ww;
    fill-opacity: 0.4;
    stroke: #3281af;
  }

  .node text {
    font-size: 10px;
    pointer-events: none;
  }

  path.link {
    fill: none;
    stroke: #3281af;
  }

  #chart {
    width: 100vw;
    position: relative;
    left: 50%;
    right: 50%;
    margin-left: -50vw;
    margin-right: -50vw;
  }

  label {
    font-size: 13px;
    font-weight: 500;
    color: $b2;
    margin-bottom: 8px;

    &.select {
      overflow: hidden;
      position: relative;
      margin-bottom: -4px;
      width: 100%;

      &:after {
        font-family: 'Font Awesome 5 Pro';
        font-weight: 400;
        content: '\f107';
        padding: 10px;
        position: absolute;
        right: 0;
        top: 27px;
        z-index: 1;
        text-align: center;
        width: 28px;
        pointer-events: none;
        box-sizing: border-box;
        font-size: 12px;
        line-height: 1;
        color: $b3;
      }

      select {
        margin-top: 8px;
      }
    }
  }

  .form-check {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: left;
    gap: 20px;

    &.task-config-radio-button label {
      min-width: 130px;
    }

    label {
      cursor: pointer;
      display: flex ;
      flex-wrap: nowrap;
      gap: 10px;
      align-items: center;
      justify-content: center;
      margin-bottom: 0;
    }

    input {
      cursor: pointer;
      position: relative;
      margin: 0 ;
    }
  }

  .custom-file-label {
    border-color: $wd;
    color: $b4;
    cursor: pointer;
    box-shadow: none;
    border-radius: 0;

    &:after {
      background-color: $we;
      border-radius: 0;
      border-left: 0;
    }
  }

  input {
    background-color: $ww;
    border-color: $wd;
    border-radius: 0px;
    cursor: pointer;

    &:focus {
      box-shadow: none;
    }
  }

  select {
    border-color: $wd;
    border-radius: 0px;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      box-shadow: none;
    }
  }

  .react-bootstrap-daterangepicker-container {
    width: 100%;

    input {
      border-right: 0;
    }

    .input-group-append {
      border: 1px solid $wd;
      border-left: 0;
      cursor: pointer;
    }

    .input-group-text {
      background-color: #e2e3e4;
      padding: 4px 9px 4px 10px;
      border: 0px;
      border-radius: 0px;
    }
  }

  #flat-payment-rate-compensation-per-item,
  #social-channel-payment-rate-facebook,
  #social-channel-payment-rate-x,
  #social-channel-payment-rate-instagram,
  #social-channel-payment-rate-youtube,
  #social-channel-payment-rate-tiktok,
  #social-channel-payment-rate-forum,
  #social-channel-payment-rate-news,
  #social-channel-payment-rate-blog {
    border-right: 0;

    ~ .input-group-append {
      border: 1px solid $wd;
      border-left: 0;

      .input-group-text {
        background-color: #e2e3e4;
        padding: 4px 9px 4px 10px;
        border: 0;
        border-radius: 0;
      }
    }
  }

  .back-button {
    @include back-button;
  }

  .save-button {
    @include save-button;
  }

  .submit-button {
    @include submit-button;
  }

  .reject-button {
    @include reject-button;
  }

  @include for-phone-only {
  }

  @include for-tablet-portrait-up {
    .task-create-container {
      max-width: 718px;
    }
  }

  @include for-tablet-landscape-up {
  }

  @include for-desktop-up {
  }

  @include for-big-desktop-up {
  }
}

.daterangepicker {
  border: 1px solid #e2e3e4;
  box-shadow: 0 3px 3px -3px #b7b9bc;

  td.active,
  td.active:hover,
  td.active.in-range {
    background-color: #32a3aa !important;
  }

  td.in-range {
    background-color: #cce8e9 !important;
  }

  td.start-date,
  td.end-date {
    border-radius: 0;
  }

  select.hourselect,
  select.minuteselect {
    background-color: $ww;
    border: 1px solid #e2e3e4;
    padding: 3px;
    border-radius: 2px;
    line-height: 22px;
  }

  .drp-buttons .btn {
    font-weight: normal;

    &.applyBtn {
      background-color: #32a3aa;
      border-color: #32a3aa;
      color: $ww;
      font-weight: bold;
    }
  }
}
