@import 'custom-variables';

//extend
%selection {
  background-color: $white;
  border: 1px solid $gray-700;
  content: '';
  cursor: pointer;
  display: inline-block;
  height: 18px;
  width: 18px;
  margin-right: 1em;
  position: relative;
  top: -1px;
  vertical-align: middle;
  transition: all .3s ease;
}

%disabled-link {
  cursor: not-allowed;
  pointer-events: none;
}

.selection-container {
  margin: 0 auto;
  padding: 3rem;
  width: 50%;
  @media #{$mobile} {
    width: 100%;
    padding: 1rem;
  }

  .selection {
    margin-bottom: 1rem;
    &:last-child {
      margin-bottom: 0;
    }
  }

  h1 {
    font-size: 18px;
  }
  //Radio button
  .radio {
    display: inline-block;
    margin: .5rem;
    input[type="radio"] {
      display: none;
      + .radio-label {
        &:before {
          @extend %selection;
          border-radius:100%;
        }
        &:hover {
        &:before {
          border: 1px solid $primary;
        }
      }
      }
      &:checked {
        + .radio-label {
          &:before {
            background-color: $primary;
            border: 1px solid $primary;
            box-shadow: inset 0 0 0 3px $white;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            border-color: $primary;
            outline: none;
          }
        }
      }
      &:disabled {
        + .radio-label {
          color: $gray-200;
          &:before {
            // background-color: $gray-200;
            border: 1px solid $gray-200;
            box-shadow: inset 0 0 0 3px $white;
            @extend %disabled-link;
          }
        }
      }
    }
  }

  //Checkbox
  .checkbox {
    display: inline-block;
    margin: .5rem;
    input[type="checkbox"] {
      display: none;
      + .checkbox-label {
        &:before {
          @extend %selection;
          border-radius: 2px;
          color: $white;
          font-family: "Font Awesome 5 Pro";
          font-size: 13px;
          text-align: center;
        }
        &:hover {
        &:before {
          border: 1px solid $primary;
        }
      }
      }
      &:checked {
        + .checkbox-label {
          &:before {
            background-color: $primary;
            border: 1px solid $primary;
            content:'\f00c';
          }
        }
      }
      &:disabled {
        + .checkbox-label {
          color: $gray-200;
          &:before {
            border: 1px solid $gray-200;
            @extend %disabled-link;
          }
        }
      }
    }
  }

  //Switch
  .switch {
    display: inline-block;
    margin: .5rem;
    position: relative;
    input[type="checkbox"] {
      display: none;
      + .switch-label {
        &:before {
          @extend %selection;
          background-color: #9FA2A5;
          border-color: #9FA2A5;
          width: 34px;
          border-radius: 13px;
        }
        &:after {
          background-color: $white;
          border-radius: 100%;
          box-shadow: 1px 1px 4px -1px $gray-900;
          content: '';
          cursor: pointer;
          height:14px;
          left: 2px;
          position: absolute;
          top: 6px;
          width: 14px;
          transition: .5s;
        }
      }
      &:checked {
        + .switch-label {
          &:before {
            background-color: $primary;
            border: 1px solid $primary;
          }
          &:after {
            background-color: $white;
            transform: translateX(1.3rem);
            left: -3px;
          }
        }
      }
      &:disabled {
        + .switch-label {
          color: $gray-200;
          @extend %disabled-link;
          &:before {
            background-color: $gray-100;
            border: 1px solid $gray-100;
          }
          &:after {
            box-shadow: none;
          }
        }
      }
    }
  }
}

