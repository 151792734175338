/* Font Size */
$h1: 2.25em;
$h2: 2em;
$h3: 1.75em;
$h4: 1.5em;
$h5: 1em;
$p: .8125em;

$title: 18px;
$subtitle: 1.25em;
$detail: 1.05em;
$label: .8125em;
$tag: 0.75em;

/* Color */
$black-color: #2c2e30;
$black-color-hover: #212224;
$dark-gray-color: #6f7479;
$dark-gray-color-hover: #4d5154;
$gray-color: #9a9da1;
$gray-color-hover: #7d8186;
$light-gray-color: #f0f1f1;
$light-gray-color-hover: #e2e3e4;
$white-color: #fff;
$white-color-hover: #f0f1f1;
$green-color: #329564;
$green-color-hover: #006231;
$warning-color: #f3cf32;
$warning-color-hover: #f1c400;
$red-color: #ba0020;
$red-color-hover: #940019;
$blue-color: #3281af;
$blue-color-hover: #004e7c;
$dark-blue-color: #325276;
$dark-blue-color-hover: #004e7c;
$turquoise-color: #32a3aa;
$turquoise-color-hover: #007077;


$ww:#fff;
$wf:#f0f1f1;
$we:#e2e3e4;
$wd:#d3d5d6;
$wc:#8b8f93;
$b4:#63686c;
$b3:#4d5154;
$b2:#373a3c;
$b1:#212224;
$b0:#161718;
$tur:#32a3aa;
$turh:#007077;
$red:#ba0020;
$redh: #940019;
$green: #329564;
$greenh: #006231;
$blue: #3281af;
$blueh: #004e7c;
$dblue: #325276;
$dblueh: #004e7c;
$warning: #f3cf32;
$warningh: #f1c400;